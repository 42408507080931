import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AppStore } from '../../../store/applicationState';
import { ActivateCode } from '../../modals';
import { KeyIcon } from '../../svgs/KeyIcon';
import { MessageEmpty } from './MessageEmpty';
import { Product } from './Product';

const WrapperS = styled.div`
  padding-top: 64px;
  display: flex;
  width: 100%;
  min-height: 500px;
  flex-wrap: wrap;
  @media (min-width: 1024px) {
    flex-wrap: nowrap;
    justify-content: center;
  }
`;

const MainS = styled.div`
  width: 866px;
  margin-right: -2px;
  max-width: 100%;
`;

const TabConteinerS = styled.div`
  margin-bottom: 32px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9dce3;
  max-width: 100%;
  overflow: auto;
  overflow-y: hidden;
`;

const LinkWrapperS = styled.div<{ selected?: boolean }>`
  display: flex;
  display: block;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 6px;
  ${({ selected }) =>
    selected
      ? `
      a {
        color: #14151a;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        bottom: -1px;
        background-color: #14151a;
      }
      `
      : ``}
`;

const ButtonS = styled.button`
  color: #d18f0a;
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ButtonIconS = styled.span`
  display: inline-block;
  margin-right: 6px;
  svg {
    fill: currentColor;
    display: block;
  }
`;

const ProductsConteinerS = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductSidebarS = styled.div`
  width: 100%;
  @media (min-width: 1440px) {
    width: 400px;
  }
  @media (min-width: 1440px) {
    width: 400px;
  }
  @media (min-width: 1024px) {
    width: 30%;
    flex-shrink: 0;
  }
`;

export const Products: React.FC = () => {
  const { Products } = useSelector((store: AppStore) => store);
  const [isModal, setModal] = React.useState(false);

  const params = useLocation();

  React.useEffect(() => {
    if (params?.search?.includes('codeactivation=1')) {
      setModal(true);
    }
  }, [params.search]);

  return (
    <WrapperS>
      <MainS>
        <h2 style={{ marginBottom: '24px' }}>Продукти</h2>
        <TabConteinerS>
          <LinkWrapperS selected>
            <a href="/products">Мої продукти</a>
          </LinkWrapperS>
          <ButtonS onClick={() => setModal(true)}>
            <ButtonIconS>
              <KeyIcon />
            </ButtonIconS>
            Активувати код доступу
          </ButtonS>
        </TabConteinerS>
        <ProductsConteinerS>
          {Products.data && Products.data.length > 0 ? (
            Products.data.map((row, i) => {
              return <Product key={`${i}-${row.absnum}`} first={i === 0} product={row} />;
            })
          ) : (
            <MessageEmpty handleOpen={() => setModal(true)} />
          )}
        </ProductsConteinerS>
      </MainS>
      <ProductSidebarS />
      {isModal ? <ActivateCode handleClose={() => setModal(!isModal)} /> : null}
    </WrapperS>
  );
};
