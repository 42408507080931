import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppStore } from '../../store/applicationState';
import { ActivateProduct } from '../../store/products/actions';
import { Logout, setAuth, setUser } from '../../store/user/actions';
import { AttetionIcon } from '../svgs/AttetionIcon';
import { CloseIcon } from '../svgs/CloseIcon';
import { KeyIcon } from '../svgs/KeyIcon';

const ModalS = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
`;
const WrapperS = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const ModalInnerS = styled.div`
  max-width: 646px;
  width: 646px;
  background: #fff;
  box-shadow: 0 12px 40px rgb(20 21 25 / 30%), 0 4px 12px rgb(20 21 25 / 20%);
  border-radius: 10px;
  position: relative;
  margin: 50px auto;
`;

const CloseS = styled.span`
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const HeaderS = styled.h2`
  font-size: 30px;
  line-height: 44px;
  letter-spacing: -0.2px;
  position: relative;
  font-weight: 600;
  padding: 32px 40px 6px;
`;

const ContentS = styled.div`
  padding-bottom: 32px;
  overflow: visible;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  flex: 1;
  padding: 10px 40px 32px;
`;

const ActivaionModalS = styled.div`
  margin-bottom: 16px;
  position: relative;
`;

const InputModalS = styled.input`
  font-size: 16px;
  height: 40px;
  line-height: 22px;
  padding: 8px 0;
  padding-left: 40px !important;
  padding-right: 40px !important;
  background: #fff;
  border: 1px solid #cfd5e6;
  border-radius: 3px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  width: 100%;
  outline: none;
  &:focus {
    border: 1px solid #0095ff;
  }
`;

const WrapperIconS = styled.div`
  left: 12px;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  vertical-align: middle;
  z-index: 1;
`;

const BtnS = styled.button<{ disabled?: boolean }>`
  background-color: var(--blueColor);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  line-height: 24px;
  padding: 8px 24px;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center;
  outline: none;
  text-align: center;
  white-space: nowrap;
  appearance: none;
  border: 1px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  appearance: none;
  border: 1px solid transparent;
  align-items: center;
  ${({ disabled }) =>
    disabled
      ? `
      background-color: var(--grayColor);
    `
      : ''}
`;

const WarningS = styled.div`
  margin: 15px 0;
  font-size: 14px;
  line-height: 20px;
  color: var(--redColor6);
`;

const WrapperBlockS = styled.span`
  position: relative;
  &:hover {
    div {
      display: block;
    }
  }
`;

const IconS = styled.span`
  margin-left: 4px;
  cursor: pointer;
  position: absolute;
  bottom: -7px;
  svg {
    height: 24px;
    width: 24px;
    display: block;
    fill: var(--blueColor);
  }
`;

const HintS = styled.div`
  display: none;
  position: absolute;
  background: #fff;
  box-shadow: 0 12px 40px rgb(20 21 25 / 30%), 0 4px 12px rgb(20 21 25 / 20%);
  z-index: 2;
  padding: 10px 15px;
  border-radius: 3px;
  width: 340px;
  left: -140px;
  top: 22px;
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 5px solid white;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: -4px;
    left: 151px;
  }
`;

interface PropsType {
  type?: 1 | 2;
  email?: string;
  handleClose: () => void;
}

export const ActivateCode: React.FC<PropsType> = ({ type, email, handleClose }) => {
  const [error, setError] = React.useState('');
  const [code, setCode] = React.useState('');
  const { Products } = useSelector((store: AppStore) => store);

  const dispatch = useDispatch();

  const handleActivate = () => {
    dispatch(
      ActivateProduct.request({
        data: {
          code,
        },
        callBack: (succ, data) => {
          if (!succ && data.message) {
            setError(data.message);
          }
          if (succ) {
            handleClose();
          }
        },
      })
    );
  };

  const handleLogout = () => {
    dispatch(setAuth(false));
    dispatch(setUser(null));
    dispatch(Logout.request({}));
  };

  const renderBody = () => {
    if (type === 1) {
      return (
        <div style={{ marginBottom: '24px' }}>
          Щоб активувати новий продукт, введіть код, який отримали від нас.
          <br />
          Якщо ваш продукт уже активовано, перевірте, чи не зайшли під іншим логіном. Зайдіть через логін, за яким матимете доступ
          <WrapperBlockS>
            <IconS>
              <AttetionIcon />
            </IconS>
            <HintS>
              Наразі ви залогінені через електронну пошту <strong>{email}</strong>. Можливо, ваші продукти прив’язані до іншого акаунта. Спробуйте 
              <Link style={{ lineHeight: 0 }} to="/login" onClick={handleLogout}>
                вийти
              </Link>
               і знову зайти під іншим своїм логіном — це ваша електронна пошта. При необхідності скористайтеся відновленням паролю.
              <br />
              <br />
              <Link to="/login" onClick={handleLogout}>
                Вийти з {email}
              </Link>
            </HintS>
          </WrapperBlockS>
        </div>
      );
    }
    return <div style={{ marginBottom: '24px' }}>Код активується лише один раз.</div>;
  };

  const handleInputChange = (event:any) => {
    // Видаляємо все, що не є числом
    let value = event.target.value.replace(/[^0-9]/g, '');

    // Обрізаємо значення, якщо воно більше 24 символів
    value = value.substring(0, 24);

    // Вставляємо дефіси
    value = value.replace(/(\d{4}(?=\d))/g, "$1-");

    // Змінюємо значення
    setCode(value);
  };

  return (
    <ModalS>
      <WrapperS>
        <ModalInnerS>
          <CloseS onClick={handleClose} style={{ height: '24px', width: '24px' }}>
            <CloseIcon />
          </CloseS>
          <HeaderS>Активація продукту за кодом доступу</HeaderS>
          <ContentS>
            {renderBody()}
            <ActivaionModalS>
            <InputModalS 
                type="text" 
                value={code} 
                onChange={handleInputChange} 
                style={{ paddingRight: '12px', paddingLeft: '32px' }} 
              />
              <WrapperIconS>
                <KeyIcon />
              </WrapperIconS>
            </ActivaionModalS>
            {error ? <WarningS>{error}</WarningS> : null}
            <div>
              <BtnS disabled={Products.loading || !code} onClick={handleActivate}>
                Активувати
              </BtnS>
            </div>
          </ContentS>
        </ModalInnerS>
      </WrapperS>
    </ModalS>
  );
};
